/* import __COLOCATED_TEMPLATE__ from './teammate-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import { PartGroupCategory } from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type AdminNote from 'embercom/objects/inbox/renderable/admin-note';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';
import { getLanguageFromPartGroup, translatedPartExists } from '../helpers/translations';
import type IntlService from 'ember-intl/services/intl';

export interface TeammateRenderablePart extends RenderablePart {
  renderableData: AdminComment | AdminNote;
}

interface TeammatePartGroup extends PartGroup {
  parts: Array<TeammateRenderablePart>;
}

interface Args {
  partGroup: TeammatePartGroup;
  conversation?: Conversation;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamTeammateContentComponent extends Component<Signature> {
  categories = PartGroupCategory;
  renderableType = RenderableType;
  @service declare session: Session;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;
  @service declare intl: IntlService;
  @tracked showOriginalMessageToggle = true;

  get teammate(): AdminSummary {
    return this.args.partGroup.parts[0].renderableData.adminSummary;
  }

  get lastPartSeenByUser() {
    let { conversation } = this.args;

    return (
      conversation?.lastSeenByUserAt &&
      conversation?.lastSeenByUserAt >= this.lastPartInGroup.createdAt
    );
  }

  get lastPartInGroup() {
    let { parts } = this.args.partGroup;
    return parts[parts.length - 1];
  }

  get isOperator() {
    let lastPart = this.lastPartInGroup;
    return lastPart.renderableType === RenderableType.TicketStateUpdated;
  }

  get autoTranslationEnabled() {
    return this.conversationTranslationSettings.autoTranslationEnabledForConversation(
      this.args.conversation?.id,
    );
  }

  get showOriginalMessage(): boolean {
    if (!this.autoTranslationEnabled) {
      return true;
    }
    return this.showOriginalMessageToggle;
  }

  get showTranslatedMessage() {
    return !this.showOriginalMessage;
  }

  get translatedPartExists() {
    return translatedPartExists(this.args.partGroup);
  }

  get translatedLanguage() {
    return getLanguageFromPartGroup(this.args.partGroup, this.intl.primaryLocale) || '';
  }

  get isTranslationForSocialChannelsEnabled() {
    return this.conversationTranslationSettings.isTranslationForSocialChannelsEnabled(
      this.args.conversation?.id,
    );
  }

  @action toggleTranslation() {
    this.showOriginalMessageToggle = !this.showOriginalMessageToggle;
  }

  @action onLinkedTicketClick(part: RenderablePart) {
    if (!part.renderableData.linkedTicket?.id) {
      return;
    }
    this.inboxSidebarService.previewConversationById(part.renderableData.linkedTicket.id, {
      selectedTab: 'activity',
      shouldFocusComposer: true,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::TeammateContent': typeof ConversationStreamTeammateContentComponent;
    'inbox2/conversation-stream/part-groups/teammate-content': typeof ConversationStreamTeammateContentComponent;
  }
}
